<template>
  <div>
    <!-- <BaseHeader :title="'Checkout'"></BaseHeader> -->
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="$store.state.loading"
    ></BaseLoading>

    <div v-else class="card mt-2 border-0 bg-white shadow">
      <div class="card-body">
        <div class="row" v-if="Object.keys(order).length > 0">
          <div class="col-12 text-left" v-if="order.is_paid">
            <div class="media alert alert-success">
              <div class="mr-3">
                <a
                  href="#"
                  class="
                    btn
                    bg-transparent
                    border-success
                    text-success
                    rounded-round
                    border-2
                    btn-icon
                  "
                >
                  <i class="icon-checkmark3"></i>
                </a>
              </div>

              <div class="media-body">
                Your order is paid!
                <div class="text-muted">We've received your order payment!</div>
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 col-md-8 text-left" sm="6">
            <h1
              class="font-weight-bold text-capitalize order-title"
              style="font-size: 1.6rem"
            >
              Order ID #{{ order.id || "" }}
            </h1>
            <p>
              <strong>{{ order.subject_area_text || "" }}</strong> |
              <strong>{{ order.academic_level_text || "" }}</strong> |
              <strong>{{ order.pages || "" }} page(s)</strong>
            </p>
          </div>

          <div class="col-12 col-sm-6 col-md-4" sm="6">
            <div class="m-0 text-right">
              <span class="font-weight-bold"> </span>
              <div class="btn-group my-1">
                <button
                  class="btn btn-outline-primary"
                  data-toggle="modal"
                  data-target="#tipModal"
                >
                  Tip Writer
                </button>
                <router-link
                  :to="{ name: 'Order', params: { id: order.id } }"
                  class="btn btn-primary"
                  >View Order</router-link
                >
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col12 col-sm-6 col-md-8 table-responsive">
                <div class="white-block order-block">
                  <div class="t-order__head-wrap">
                  <div class="t-order__top-wrap">
                  <!-- <h3 class="t-page-title uppercase">
                  Order #3347411 </h3> -->
                  <!-- <a href="/customers/order/form/3347411?of_id=1989" class="t-btn t-btn_default" role="button">
                  Edit Order </a> -->
                  </div>
                  <!-- <div class="t-row-start t-order-state">
                  <div class="progress-state right-margin not_paid">
                  <div class="t-order__status-name">Not paid</div>
                  </div>
                  <span class="progress-state-text not_paid fs13 ">
                  Your order has not been paid yet. </span>
                  </div> -->
                  <div class="t-row">
                  <div class="lato-700">
                 Title: Any topic (Writer's choice) </div>
                  </div>
                  </div>
                  <div class="t-order-info__wrap">
                  <div class="t-order-info t-block_border-dot t-block_bg-grey">
                  <div class="t-info-flex">
                  <div class="t-info-flex-w">
                  <div class="t-info">
                  <div class="t-info__key">Creation date:</div>
                  <div class="t-info__date-wrap">
                  <div class="t-info__val">{{order.date_placed}}</div>
                  <!-- <div class="t-info__val t-info__val_l-grey">10:53 AM</div> -->
                  </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Urgency:</div>
                  <div class="t-info__val">{{order.urgency_text_hrs}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Subject:</div>
                  <div class="t-info__val">{{order.subject_area_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Type of work:</div>
                  <div class="t-info__val">{{order.document_type_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Number of pages:</div>
                  <div class="t-info__val">
                  {{order.pages}} </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Spacing:</div>
                  <div class="t-info__val">{{order.spacing_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Charts:</div>
                  <div class="t-info__val">{{order.charts}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Slides:</div>
                  <div class="t-info__val">{{order.slides}}</div>
                  </div>
                  </div>
                  <div class="t-info-flex-w">
                  <div class="t-info">
                  <div class="t-info__key">Level:</div>
                  <div class="t-info__val">{{order.academic_level_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Style:</div>
                  <div class="t-info__val">{{order.style_text}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Sources:</div>
                  <div class="t-info__val">{{order.no_sources}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Language:</div>
                  <div class="t-info__val">{{order.language_style_text}}</div>
                  </div>

                  <div class="t-info">
                  <div class="t-info__key">Extras Total:</div>
                  <div class="t-info__val-wrap">
                  <div class="t-info__val t-info__val_separate">
                  <div class="t-vt-separator">
                    ${{ order.extras_total || 0 }}</div>
                  </div>
                  </div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Total Order Cost:</div>
                  <div class="t-info__val">${{order.cost}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Paid:</div>
                  <div class="t-info__val">${{order.total_paid}}</div>
                  </div>
                  <div class="t-info">
                  <div class="t-info__key">Balance:</div>
                  <div class="t-info__val"> ${{ order.balance || 0 }}</div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="t-row py-3">
                  <div class=" t-deadline__wrap">
                  <div class="t-deadline t-deadline_with-icon">
                  <div class="d-flex-center">
                  <div class="t-deadline__icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" fill="none" viewBox="0 0 14 15"><path fill="#ABB1BA" d="M3.099 6.745H7v3.759H3.099v-3.76zm9.333 6.744V5.27H1.568v8.22h10.864zm0-11.978c.413 0 .778.152 1.094.456.316.305.474.656.474 1.054V13.49c0 .398-.158.749-.474 1.053a1.53 1.53 0 0 1-1.094.457H1.568c-.438 0-.815-.14-1.13-.421A1.52 1.52 0 0 1 0 13.489V3.021c0-.398.146-.75.438-1.054a1.572 1.572 0 0 1 1.13-.456h.765V0h1.568v1.51h6.198V0h1.568v1.51h.765z"></path></svg>
                  </div>
                  <div class="t-deadline__label">
                  Deadline:
                  </div>
                  <div class="t-deadline__time">
                  <div id="date-real-deadline_date" class="t-info__val">{{order.deadline && order.deadline.ellapsed }}</div>
                  <!-- <div id="date-real-deadline_time" class="t-info__val t-info__val_l-grey">10:53 AM</div> -->
                  </div>
                  </div>
                  </div>
                  <div class="t-deadline__extend-link">
                  </div>
                  </div>
                  <div class="t-col-2">
                  </div>
                  </div>
                  <div class="t-order__bottom-wrap t-row t-block_bg-grey t-block_border-dot">
                  <div class="t-order__not-paid d-flex flex-sm-row flex-column  align-items-sm-center align-items-start justify-content-between ">
                  <div class="d-flex  t-order__paid-wrap">
                    <span class="t-order__paid-label">
                    Placed:
                    </span>
                    <span class="t-order__paid-val">
                    <span class="green">${{order.cost}} </span> 
                    </span>

                  </div>
                  <div class="t-payment-list__order-wrap">
                  <div class="t-payment-list__wrap">
                  <div class="t-payment-list js-loader_hide">
                  <div class="selectr-container selectr-desktop has-selected" style="width: 100%;">

                  
                  </div> </div>
                  
                  <div class="t-payment-list__btn-wrap">
                  <router-link :to="{ name: 'payment', params: { id: order.id } }" id="checkoutBtn" class="t-payment-list__btn ms-700">Secure checkout</router-link>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  </div>
                  <div class="t-block t-block_white t-instructions_noconfirm">
                  <div class="t-flex align-items-center justify-content-start">
                  <h3 class="t-page-title t-page-title_uppercase">
                  Instructions </h3>
                  </div>
                  <div id="instructions" class="t-instructions__list">
                  <div class="list-item">
                  <div class="t-instructions__list-item">
                  <div class="t-instructions__list-content">
                    <!-- {{order}} -->
                    <span v-html="order.description" ></span>

                  </div>
                  <div :class="order.deadline && order.deadline.past_due ? 'text-danger' : 'text-success' "  class="t-instructions__list-content_light">
                  Placed: {{order.deadline && order.deadline.ellapsed }}</div>
                  </div>
                  </div>
                  <div class="bg-linear hide"></div>
                  </div>
                
                  </div>
              </div>

              <div class="col-12 col-sm-6 col-md-4 text-left">
                <div
                  class=" payment_methods  py-3"
                  style=" max-width: 280px"
                > 
                  <div class="card-body">
                    <h5 class="font-weight-bold">Card Checkout</h5>
                    <span v-html="payment_vars.message"></span>
                    <div
                      class="
                        d-flex
                        flex-row
                        justify-content-between
                        align-content-center
                      "
                    >
                      <img
                        class="d-sm-inline"
                        width="40%"
                        src="../../assets/global_assets/images/visa.png"
                        alt="Credit and debit card"
                      />
                      <img
                        width="40%"
                        class="d-sm-inline"
                        src="../../assets/global_assets/images/mastercard.png"
                        alt="Credit and debit card"
                      />
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small>Order Price</small>
                      <h3 class="font-weight-black ml-1 my-0">
                        {{ order.cost || "" }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small>Total Extras</small>
                      <h3 class="font-weight-black ml-1 my-0">
                        {{ order.extras_total || 0 }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small>Paid</small>
                      <h3 class="font-weight-black text-success ml-1 my-0">
                        {{ order.total_paid || 0 }} USD
                      </h3>
                    </div>
                    <div
                      class="
                        my-1
                        d-flex
                        flex-row
                        justify-content-between
                        align-items-baseline
                      "
                    >
                      <small>Balance</small>
                      <h3 class="font-weight-black text-danger ml-1 my-0">
                        {{ order.balance || 0 }} USD
                      </h3>
                    </div>
                    <a
                      class="btn btn-primary btn-block mt-2 btn-lg py-2"
                      :href="payment_vars.checkout || '#'"
                      >Card Checkout</a
                    >
                    <button
                      class="btn btn-outline-primary btn-block btn-lg mt-2 mb-1"
                      @click="walletPaymentUrl"
                    >
                      Pay From Wallet
                    </button>
                    <button
                      class="btn btn-link btn-block btn-lg"
                      @click="loadPaymentUrl"
                    >
                      Refresh Payment Token
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <BaseOverlay v-if="is_loading"></BaseOverlay>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  name: "Order",
  components: {},
  computed: {
    ...mapState("order", ["order"]),
    ...mapState("payment", ["paymentForm", "payment_vars"]),
  },
  data() {
    return {
      is_loading: false,
    };
  },

  methods: {
    ...mapActions("order", ["_getOrder", "deleteExtras"]),
    ...mapMutations("order", ["unsetOrder"]),
    ...mapActions("payment", ["_getPaymentUrl"]),
    ...mapMutations("payment", ["_setPaymentForm", "setPaymentUrl"]),
    ...mapActions("sidebar", ["getSidebarMenu"]),

    walletPaymentUrl() {
      let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/WalletCheckout`;
      this.$store.commit("payment/setPaymentUrl", paymentUrl);
      this.paymentForm.type = "order";
      this.paymentForm.reference = this.order.id;
      this.is_loading = true;
      this._getPaymentUrl(this.paymentForm)
        .then((res) => {
          console.log(res);
          if (res.data.Type) {
            this.$notify({
              title: "Success",
              text: res.data.Message || "Payment successiful!",
              style: "success",
            });
          } else {
            this.$notify({
              title: "Error",
              text: res.data.Message || "Payment could not be processed!",
              style: "danger",
            });
          }

          this.is_loading = false;
        })
        .catch((err) => {
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
          console.log(err);
        })
        .finally(() => {
          this.is_loading = false;
          this._getOrder(this.$route.params.id);
          this.getSidebarMenu();
        });
    },
    deleteExtra(id) {
      this.is_loading = true;
      this.deleteExtras(id)
        .then((res) => {
          console.log(res);
          // this,_getOrder(this.order.id)
          // alert
          this.$notify({
            title: "Success",
            text: "Order extra deleted successiful!",
          });
        })
        .catch((err) => {
          console.log(err);
          // alert
          this.$notify({
            title: "Error",
            text: err.response.data ? err.response.data.Message : "Error",
            style: "danger",
          });
        })
        .finally(() => {
          this.is_loading = false;
        });
    },

    async loadPaymentUrl() {
      let paymentUrl = `${process.env.VUE_APP_API_BASE_URL}/Checkout/GetUrl`;
      this.$store.commit("payment/setPaymentUrl", paymentUrl);
      this.$store.state.loading = true;
      await this._getOrder(this.$route.params.id);
      this.paymentForm.type = "order";
      (this.paymentForm.amount = this.order.cost),
        (this.paymentForm.reference = this.order.id);

      this.paymentForm.call_back = document.location.href;
      await this._getPaymentUrl(this.paymentForm);
      await this.$nextTick();
    },
  },

  async mounted() {
    await this.loadPaymentUrl();
  },

  beforeDestroy() {
    this.unsetOrder();
  },
};
</script>
